import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "./assets/css/index.scss";
import "./assets/css/data.scss";
import { premiSub } from "@/utils";
import { Message } from 'element-ui'
// import "./utils/message"

import VMdPreview from '@kangc/v-md-editor/lib/preview.js'
import '@kangc/v-md-editor/lib/style/preview.css'
// markdown 主题
import vuepress from '@kangc/v-md-editor/lib/theme/vuepress.js'
import '@kangc/v-md-editor/lib/theme/style/vuepress.css'
import emoji from '@kangc/v-md-editor/lib/plugins/emoji/index'
import '@kangc/v-md-editor/lib/plugins/emoji/emoji.css'


Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(premiSub);


VMdPreview.use(vuepress)
VMdPreview.use(emoji)
    // md 插件
Vue.use(VMdPreview)

Vue.prototype.$message = function(msg) {
    return Message({
        message: msg,
        showClose: true,
        duration: 0
    })
}
Vue.prototype.$message.success = function(msg) {
    return Message.success({
        message: msg,
        duration: 3000
    })
}
Vue.prototype.$message.warning = function(msg) {
    return Message.warning({
        message: msg,
        showClose: true,
        duration: 0
    })
}
Vue.prototype.$message.error = function(msg) {
    return Message.error({
        message: msg,
        showClose: true,
        duration: 0
    })
}

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");