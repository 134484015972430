const restemp = [];
export function recurRouter(getRouter = [], allRoutes = []) {
    const resultRoutes = [];


    getRouter.forEach(({ name, isSelected, children, label }) => {
        allRoutes.forEach((i) => {
            if (name === i.meta.title && isSelected) {
                if (children && children.length > 0) {
                    i.children = recurRouter(children, i.children);
                }
                resultRoutes.push(i);
            }
        });
        if (isSelected) {
            restemp.push(label);
            children = recurRouter(children);
        }
    });

    localStorage.setItem('premisRes', JSON.stringify(restemp));
    return resultRoutes;
}

export function recurPrem(data = []) {
    const respremis = JSON.parse(localStorage.getItem('premisRes')) || [];

    data.forEach((item) => {
        if (item.children) {
            recurPrem(item.children);
        }
        if (item.label && item.parentId !== '-1') {
            respremis.push(item.label);
        }
    });

    localStorage.setItem('premisRes', JSON.stringify(respremis));
    return respremis;
}