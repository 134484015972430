const premiSub = {
    install(Vue) {
        Vue.directive('premiSub', {
            inserted: (el, bindings) => {
                subPermission(el, bindings);
            }
        })
    }
}

export const subPermission = (el, bindings) => {
    const premissionLists = localStorage.getItem('premisRes');
    const hasPemission = premissionLists.includes(bindings.value);
    if (!hasPemission) {
        setTimeout(() => {
            el.parentNode && el.parentNode.removeChild(el);
        }, 0)
    }
}

export default premiSub