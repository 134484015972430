import { login } from '@/api'
import router, { DynamicRoutesz } from '../router'
import { recurRouter } from '../utils/common'
import allroutes from '../router/aroutes'
import { Message, MessageBox } from "element-ui";
import { apsSSE } from "../utils/sse"


const actions = {
    // 登录
    async LOGINACT({ commit }, logins) {
        if (logins) {
            const ticket = window.location.search.substring(8);
            const params = { ticket };
            const res = await login(params);
            const { code, msg } = res;
            console.log('res:', res);
            if (code === 0) {
                const { token, isSupportMes, trees, userName } = res.data;
                commit('stateValueHandler', { name: 'userName', value: userName });
                commit('stateValueHandler', { name: 'isSupportMes', value: isSupportMes });
                commit('stateValueHandler', { name: 'apsToken', value: token });
                commit('stateValueHandler', { name: 'permissionLists', value: JSON.stringify(trees) });
            } else {
                Message({
                    type: 'error',
                    showClose: true,
                    message: msg,
                    duration: 0
                });
                return;
            }
        }

        const permissionLists = JSON.parse(localStorage.getItem('permissionLists'));
        if (!permissionLists) {
            Message({
                type: 'error',
                showClose: true,
                message: '接口数据请求失败,请联系技术人员',
                duration: 0
            });
            return;
        }

        const tempData2 = recurRouter(permissionLists, allroutes);
        DynamicRoutesz.children = [];
        const containerRoutes = DynamicRoutesz.find(i => i.path === "/");
        const children = containerRoutes.children;
        children.push(...tempData2);
        commit('SET_MENU', tempData2);

        const initialRoutes = router.options.routes;
        for (let i = 0; i < DynamicRoutesz.length; i++) {
            router.addRoute(DynamicRoutesz[i]);
        }
        commit('SET_PERMISSION', [...initialRoutes, ...DynamicRoutesz]);
        apsSSE();
    },
    // 退出
    LOGOUTACT({ commit }, mes) {
        commit('CLEAR_MENU');
        commit('CLEAR_PERMISSION');
        commit('stateValueHandler', { name: 'permissionLists', value: '' });
        commit('stateValueHandler', { name: 'apsToken', value: '' });
        if (mes) {
            MessageBox.confirm(`需要登录，请从高指中心登录`, `退出成功`, {
                type: 'success',
                center: true,
                showConfirmButton: false,
                showCancelButton: false,
                closeOnClickModal: false,
            });
        }
    }
};

export default actions;