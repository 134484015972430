<template>
  <div class="header-sider">
    <section class="box-sider" @click="homeHandle">
      <div class="logo_home2"></div>
      <h2>APS</h2>
      <p>先进排程</p>
      <span>V2.1</span>
    </section>
    <div><img src="@/assets/images/common/logo.png" class="logo_home" /></div>
    <section class="user-info">
      <div>
        <img src="@/assets/images/common/icon_help.png" class="pointer" @click="goDocRouter" />
        <img class="userPic" src="@/assets/images/common/logo-ligong.svg" @click="logoutVisible=true" />
        <span @click="logoutVisible=true">{{ userName }}</span>
        <span class="timeb">{{ timeb }}</span>
        <span class="times">{{ times }}</span>
        <el-popover
  placement="left"
  width="160"
  offset= 60
  v-model="logoutVisible">
              <el-button class="logoutButton"  @click="logoutHandle">退出登录</el-button>
              <el-button class="closeButton" @click="logoutVisible=false">取消</el-button>
          </el-popover>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import dayjs from 'dayjs'
export default {
  name: "HeaderBan",
  data() {
    return {
      timeb: dayjs(new Date()).format("YYYY.MM.DD"),
      times: null,
      logoutVisible: false,
    };
  },
  computed: {
    ...mapState(['userName'])
  },
  created() {
    this.getCurDate();
  },
  destroyed() {
    clearInterval(this.times)
  },
  methods: {
    ...mapActions(['LOGOUTACT']),
    getCurDate() {
      setInterval(() => {
        this.times = dayjs(new Date()).format("HH:mm")
      }, 1000)
    },
    logoutHandle() {
      this.LOGOUTACT({mes: true})
    },
    homeHandle() {
      const { href }  = this.$router.resolve({ path: '/home' })
      window.open(href)
    },
    goDocRouter() {
      this.$router.push({
        path: 'doc'
      })
    }
  },
  
};
</script>

<style lang="scss" scoped>
.header-sider {
  color: #b9b9b9;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    vertical-align: middle;
  }
  .user-info {
    padding: 0px 20px;
    height: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 18px;
    .userPic {
      width: 32px;
      height: 32px;
      margin: 0 10px;
    }
    .timeb {
      font-size: 16px !important;
      margin: 0 10px 0 30px;
    }

    .times {
      font-size: 30px;
      color: #fff;
    }
    img {
      cursor: pointer;
      margin:0px 8px;
      vertical-align: middle
    }

  }
  .header-menu {
    color: #fff !important;
    padding: 10px 20px 0 20px;
    h3 {
      font-size: 24px;
      line-height: 36px;
      margin-right: 30px;
      color: #e8e8e8;
    }
    .menu-info {
      display: flex;
      p {
        margin: -12px 0 0 0;
      }
    }
  }
  .box-sider {
    padding: 0 10px;
    color: #fff;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: baseline;
    height: 64px;
    margin: 0 20px;
    cursor: pointer;
    padding-top: 23px;
    h2 {
      font-size: 24px;
      color: #2deb79;
      margin: 0 8px;
    }
    p {
      font-size: 23px;
      white-space: nowrap !important;
      margin: 0px 2px;
    }
    span{
      font-size: 16px;
      color:#2deb79;
      margin-left: 5px;
    }
    .logo_home2 {
      width: 165px;
      height: 29px;
      background: url("../../assets/images/common/logo2.png") no-repeat center center;
      background-size: contain;
      margin: 5px 8px 0 0;
    }
  }
  .logo_home {
    width: 113px;
    height: 38px;
      
  }
}
.text1 {
  color: #fff;
}
</style>
