const mutations = {
    //公用方法，state赋值
    stateValueHandler(state, { name, value }) {
        state[name] = value
    },
    SET_MENU(state, menu) {
        state.menuLists = menu;
    },
    CLEAR_MENU(state) {
        state.menuLists = []
    },
    SET_PERMISSION(state, data) {
        state.userpermission = data;
    },
    CLEAR_PERMISSION(state) {
        state.userpermission = ""
    },
    SET_MENUCUR(state, data) {
        state.menuCur = data;
    },
    CLEAR_MENUCUR(state) {
        state.menuCur = ''
    },

};

export default mutations;