import { baseUrls } from '../utils/urls'
import { MessageBox } from "element-ui";
import store from '@/store'
const aps2 = baseUrls.aps2;

export function apsSSE() {
    if ('EventSource' in window) {
        const url = `${aps2}/admin/sse/createSseConnect?clientId=${localStorage.getItem('apsToken')}`;
        const apsES = new EventSource(url);
        apsES.onmessage = function(event) {
            var data = event.data;
            if (data.indexOf('请确认账号安全') !== -1) {
                MessageBox.confirm(`${data}`, `下线通知`, {
                    type: 'warning',
                    center: true,
                    showConfirmButton: false,
                    showCancelButton: false,
                    closeOnClickModal: false,
                });
                store.dispatch('LOGOUTACT')
            }

        };
    }
}