const state = {
    popupCloseVisable: true, // 弹出框关闭是否显示
    curSerialNum: '', //智能甘特当前流水号
    menuLists: null,
    userpermission: null,
    menuCur: '',
    isSupportMes: '', // 是否支持mes
    get userName() {
        return localStorage.getItem('userName');
    },
    set userName(vaule) {
        return localStorage.setItem('userName', vaule);
    },
    get apsToken() {
        return localStorage.getItem('apsToken');
    },
    set apsToken(vaule) {
        return localStorage.setItem('apsToken', vaule);
    },
    get permissionLists() {
        return localStorage.getItem('permissionLists');
    },
    set permissionLists(vaule) {
        return localStorage.setItem('permissionLists', vaule);
    },
    get premisRes() {
        return localStorage.getItem('premisRes');
    },
    set premisRes(vaule) {
        return localStorage.setItem('premisRes', vaule);
    },

};
export default state;