<template>
  <div class="app-wrap">
    <el-container>
      <el-header class="header-ban">
        <HeaderBan></HeaderBan>
      </el-header>
      <el-container style="margin: 0; height: 94vh">
        <el-aside width="240px">
          <SideLeft></SideLeft>
        </el-aside>
        <el-main class="mainbg">
          <SideRight></SideRight>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import HeaderBan from "./components/HeaderBan.vue";
import SideLeft from "./components/SideLeft.vue";
import SideRight from "./components/SideRight.vue";
export default {
  name: "Layout",
  data() {
    return {};
  },
  components: {
    HeaderBan,
    SideLeft,
    SideRight,
  },
};
</script>

<style lang="scss" scoped>
.app-wrap {
  width: 100vw;
  height: 100vh;
  background: #262626 !important;
  overflow: hidden;
  outline: 1px solid #f00;
}

.header-ban {
  margin: 0;
  padding: 0;
  height: 8vh !important;
  max-height: 64px;
  overflow: hidden;
  border-bottom: 2px solid #161616;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.03);
}
.mainbg {
  margin: 0;
  padding: 0;
  height: 97%;
  background: #161616;
  min-width: 1366px;
  overflow: auto;
}
</style>
